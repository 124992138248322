import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import RightArrowImg from "../assets/RAvacancy.svg";
import DeleteIcon from "../assets/newDelet.svg";
import { Modal, Button } from "react-bootstrap";
import "../Components/Css/Vacancy.css";
import * as FileSaver from "file-saver";
import FilterIcon from "../assets/filterIcon.png";
import Search from "../assets/search.png";
import {
  handleError,
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleIaphabetnumberChange,
  handleSuccess,
  inputdateformateChange,
  handleAphabetsChange,
  handleNumbersChange,
  handleNumbersDecimalChange,
  computeTodayDate,
  handleURLChange,
  handleEmailChange,
  computeplussevendays,
} from "../CommonJquery/CommonJquery";
import Loader from "./Loader.js";
import {
  server_post_data,
  save_update_Vacancy,
  get_all_Vacancy,
  delete_website_master_data,
  update_data_career,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import {
  ViewStaffPagesText,
  options_search,
} from "./../CommonJquery/WebsiteText";
import crosIcon from "../assets/crossicon.svg";
const VacancyWebsite = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  const [editStaffDataVaccancy, seteditStaffDataVaccancy] = useState([]);
  const [editStaffDataDetails, seteditStaffDataDetails] = useState([]);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [SelectedData, setSelectedData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowAccept, setModalShowAccept] = useState(false);
  const [modalShowDecline, setModalShowDecline] = useState(false);
  const [modalshudluelink, setmodalshudluelink] = useState(false);
  const [modalarrived, setmodalarrived] = useState(false);
  const [modalselected, setmodalselected] = useState(false);
  const [modalreject, setmodalreject] = useState(false);
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");
  const [formVisible, setFormVisible] = useState(false);
  const [searchfilter, setSearchFilter] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("Name"); // State to hold the selected value
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [flagData, setflagData] = useState("1");
  const handleToggleForm = () => {
    setFormVisible(!formVisible);
  };
  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get("", "", flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  useEffect(() => {}, [editStaffData]);
  const handleDeactivate = () => {
    master_data_action_update(SelectedData.primary_id, "d");
  };

  const handleDeactivateAccept = () => {
    master_data_action_update_career(SelectedData.primary_id, 1);
  };

  const handleDeactivateDecline = () => {
    master_data_action_update_career(SelectedData.primary_id, 7);
  };

  const handleArrivedAccept = () => {
    master_data_action_update_career(SelectedData.primary_id, 4);
  };

  const handleClose = () => {
    setModalShow(false);
    setmodalshudluelink(false);
    setModalShowDecline(false);
    setModalShowAccept(false);
    setmodalarrived(false);
    setmodalselected(false);
    setmodalreject(false);
  };

  const handleShow = (data_call) => {
    setSelectedData(data_call);
    setModalShow(true);
  };
  const handleShowAccept = (data_call) => {
    setSelectedData(data_call);
    setModalShowAccept(true);
  };

  const handleShowDecline = (data_call) => {
    setSelectedData(data_call);
    setModalShowDecline(true);
  };
  const handleArrived = (data_call) => {
    setSelectedData(data_call);
    setmodalarrived(true);
  };

  const handleshudluelink = (data_call) => {
    setSelectedData(data_call);
    setmodalshudluelink(true);
  };
  const handlereject = (data_call) => {
    setSelectedData(data_call);
    setmodalreject(true);
  };
  const handleselected = (data_call) => {
    setSelectedData(data_call);
    setmodalselected(true);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("search_option", selectedValue);
    fd.append("search_data", document.getElementById("search_data").value);
    await server_post_data(get_all_Vacancy, fd)
      .then((Response) => {
        setflagData(flag);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data_Career.length > 0) {
            seteditStaffData(Response.data.message.data_Career);
            setfilteredData(Response.data.message.data_Career);
            seteditStaffDataVaccancy(Response.data.message.data_vacancy);
            setsStaffImageLinkData(
              APL_LINK + Response.data.message.data_link_image
            );
            if (flag === "3") {
              seteditStaffDataDetails(Response.data.message.data_vacancy[0]);
              setEditorDatMainID(
                Response.data.message.data_vacancy[0].primary_id
              );
              setFormVisible(true);
            } else {
              seteditStaffDataDetails([]);
            }
          }
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action_update = async (call_id, for_status_final) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("id_for_delete", call_id);
    fd.append("flag_for", "9");
    fd.append("for_status_final", for_status_final);
    await server_post_data(delete_website_master_data, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleClose();
          master_data_get("", "", "1", "0");
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const downloadImage = (image_url, resume_file) => {
    FileSaver.saveAs(image_url, resume_file); // Put your image URL here.
  };
  const master_data_action_update_career = async (
    call_id,
    for_status_final
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("id_for_update", call_id);
    fd.append("flag_for", 7);
    fd.append("status_job", for_status_final);
    await server_post_data(update_data_career, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleClose();
          master_data_get("", "", "1", "0");
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            master_data_get("", "", "1", "0");
            handleSuccess(Response.data.message);
            empty_form(form_data);
            handleClose();
          }
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleSaveChangesdynamic_update = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("main_id", editorDataMainID);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            master_data_get("", "", "1", "0");
            handleSuccess(Response.data.message);
            setFormVisible(false);
            empty_form(form_data);
            handleClose();
          }
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  const openCustomDatePicker = (customDatePickerInput_id) => {
    document.getElementById(customDatePickerInput_id).focus();
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };
  const handleClearDate = (datevl) => {
    if (datevl === "startDate") {
      setStartDate("");
    }

    if (datevl === "endDate") {
      setEndDate("");
    }
  };
  const handleSelect = (event) => {
    setSelectedValue(event.target.value); // Update the selected value when an option is selected
  };
  return (
    <div>
      {showLoaderAdmin && <Loader />}
      {!formVisible && (
        <>
          <div className="headSeo">
            <h6>All Vacancy</h6>
            <button
              type="button"
              onClick={handleToggleForm}
              className="btn btn-secondary"
            >
              Add Vacancy
            </button>
          </div>
          <div className="vacancyOnOff">
            <div className="vacancyOnOff_Container">
              <div className="row m-0">
                {editStaffDataVaccancy &&
                  editStaffDataVaccancy.length > 0 &&
                  editStaffDataVaccancy.map((option, index) => (
                    <div className="col-md-3">
                      <div className="vacancyOnOffItem">
                        <div className="vacancyHead">
                          <div className="vacancyTitle">
                            <p>{option.designation}</p>
                            <p>{option.skills}</p>
                          </div>
                          <div className="dltVacancy">
                            <div
                              className="deletImgIcon dltVacancyIcon"
                              style={{ position: "static" }}
                              onClick={() => handleShow(option)}
                            >
                              <img src={DeleteIcon} alt="Barley's Dashboard" />
                            </div>
                            {/* <label className="switchSmall m5">
                              <input
                                type="checkbox"
                                onClick={() => handleShow(option)}
                              />
                              <small></small>
                            </label> */}
                          </div>
                        </div>
                        <div className="vacancyBody">
                          <p>
                            Work Exp: {option.min_experience}-
                            {option.max_experience} years
                          </p>
                          <img src={RightArrowImg} alt="Barley's Dashboard" />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="viewStaff_head mt-5 mb-3">
            <div className="row m-0">
              <div className="col-xl-2 col-sm-4 bottomAlgin">
                <label className="labelView">
                  {ViewStaffPagesText.Start_Date}
                </label>
                <div className="person__calenderFrame_image image_icon_position1 ">
                  <input
                    id="startDate"
                    type="date"
                    placeholder={ViewStaffPagesText.D_O_B}
                    className="form-control  input_field_custom4"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    max={computeTodayDate()}
                  />

                  <button
                    onClick={() => handleClearDate("startDate")}
                    className="crsBTN"
                  >
                    <div className="crsICN">
                      <img src={crosIcon} alt="barlays" />
                    </div>
                  </button>
                </div>
              </div>
              <div className="col-xl-2 col-sm-4 bottomAlgin">
                <label className="labelView">
                  {ViewStaffPagesText.End_Date}
                </label>
                <div className="person__calenderFrame_image image_icon_position1 ">
                  <input
                    id="endDate"
                    type="date"
                    placeholder={ViewStaffPagesText.D_O_B}
                    className="form-control  input_field_custom4"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    max={computeTodayDate()}
                  />

                  <button
                    onClick={() => handleClearDate("endDate")}
                    className="crsBTN"
                  >
                    <div className="crsICN">
                      <img src={crosIcon} alt="barlays" />
                    </div>
                  </button>
                </div>
              </div>
              <div className="col-xl-2 col-sm-4 bottomAlgin">
                <div className="inputDiv2">
                  <label className="labelPointer" htmlFor="selectFilter">
                    <img src={FilterIcon} alt="Barley's Dashboard" />
                  </label>
                  <select id="selectFilter" onChange={handleSelect}>
                    {options_search.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-xl-4 col-sm-8 bottomAlgin">
                <div className="inputDiv2">
                  <img src={Search} alt="Barley's Dashboard" />
                  <input
                    type="text"
                    id="search_data"
                    onInput={(e) => {
                      if (selectedValue === options_search[0].value) {
                        handleAphabetsChange(e);
                      } else if (selectedValue === options_search[1].value) {
                        handleEmailChange(e);
                      } else if (selectedValue === options_search[2].value) {
                        handleNumbersChange(e);
                      }
                    }}
                    placeholder="Search"
                  />
                  <button
                    type="button"
                    className="btnSearch"
                    onClick={() => search_data()}
                  >
                    {ViewStaffPagesText.Search_text}
                  </button>
                </div>
              </div>
              <div
                className="col-md-4 centerAlgin"
                style={{ marginLeft: "auto", marginRight: "0" }}
              >
                <div className="inputDiv2 ">
                  <label className="labelPointer" htmlFor="selectFilter">
                    <img src={FilterIcon} alt="Barley's Dashboard" />
                  </label>
                  <input
                    type="text"
                    placeholder="Search"
                    onInput={handleIaphabetnumberChange}
                    value={searchfilter}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th
                    style={{
                      color: "#F58634",
                      fontWeight: "600",
                      padding: "0px",
                      width: "4.5%",
                      fontSize: "14px",
                    }}
                  >
                    Sr.No.
                  </th>
                  <th
                    style={{
                      color: "#F58634",
                      fontWeight: "600",
                      padding: "0px",
                      fontSize: "14px",
                    }}
                  >
                    Apply Date
                  </th>
                  <th
                    style={{
                      color: "#F58634",
                      fontWeight: "600",
                      padding: "0px",
                      fontSize: "14px",
                    }}
                  >
                    Name
                  </th>
                  <th
                    style={{
                      color: "#F58634",
                      fontWeight: "600",
                      padding: "0px",
                      fontSize: "14px",
                    }}
                  >
                    Email
                  </th>
                  <th
                    style={{
                      color: "#F58634",
                      fontWeight: "600",
                      padding: "0px",
                      fontSize: "14px",
                    }}
                  >
                    Contact Info
                  </th>
                  <th
                    style={{
                      color: "#F58634",
                      fontWeight: "600",
                      padding: "0px",
                      fontSize: "14px",
                    }}
                  >
                    Position
                  </th>
                  <th
                    style={{
                      color: "#F58634",
                      fontWeight: "600",
                      padding: "0px",
                      fontSize: "14px",
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {filteredData &&
                  filteredData.length > 0 &&
                  filteredData.map((option, index) => (
                    <React.Fragment key={index}>
                      <tr className="seoTable_row">
                        <td className="col-xs serial-span">
                          <div>
                            <span>{1 + index}</span>
                          </div>
                        </td>
                        <td className="col-md">
                          <div>{inputdateformateChange(option.entry_date)}</div>
                        </td>
                        <td className="col-md">
                          <div>{option.user_name}</div>
                        </td>
                        <td className="col-md">
                          <div>{option.job_email}</div>
                        </td>
                        <td className="col-md">
                          <div>{option.job_mobile_no}</div>
                        </td>
                        <td>{option.vacancy_name}</td>
                        <td className="th4">
                          {option.resume_file &&
                            option.resume_file.length > 3 && (
                              <div className="recentReservBtns">
                                <button
                                  className="mb-2"
                                  onClick={() =>
                                    downloadImage(
                                      StaffImageLinkData + option.resume_file,
                                      option.resume_file
                                    )
                                  }
                                >
                                  <p>Download</p>
                                </button>
                              </div>
                            )}
                        </td>

                        <td className="th4">
                          {option.status_job === 0 && (
                            <div className="recentReservBtns">
                              <button
                                className="mb-2"
                                onClick={() => handleShowAccept(option)}
                              >
                                <p>Accept</p>
                              </button>
                              <button onClick={() => handleShowDecline(option)}>
                                <p>Reject</p>
                              </button>
                            </div>
                          )}
                          {option.status_job === 1 && (
                            <div className="recentReservBtns">
                              <button
                                className="mb-2"
                                onClick={() => handleshudluelink(option)}
                              >
                                <p>Schedule</p>
                              </button>
                              <button onClick={() => handleShowDecline(option)}>
                                <p>Delete</p>
                              </button>
                            </div>
                          )}
                          {option.status_job === 3 && (
                            <div className="recentReservBtns">
                              <button
                                className="mb-2"
                                onClick={() => handleArrived(option)}
                              >
                                <p>Arrived</p>
                              </button>
                              <button onClick={() => handleshudluelink(option)}>
                                <p>Re-Schedule</p>
                              </button>
                            </div>
                          )}
                          {option.status_job === 4 && (
                            <div className="recentReservBtns">
                              <button
                                className="mb-2"
                                onClick={() => handleselected(option)}
                              >
                                <p>Selected</p>
                              </button>
                              <button onClick={() => handlereject(option)}>
                                <p>Reject</p>
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr className="actionRow"></tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {formVisible && (
        <form id="addNewStaff">
          <div className="event-box blog-data">
            <button
              style={{ fontWeight: "500" }}
              type="button"
              className="btn custom-btn"
            >
              Add Vacancy
            </button>

            <div className="form-group seoAddForm">
              <div className="row m-0">
                <div className="col-md-6">
                  <div className="inpContainer inpContainerSeo mt-2">
                    <label className="no_prsnl_id">Designation </label>
                    <input
                      type="text"
                      name="designation"
                      defaultValue={editStaffDataDetails.designation || ""}
                      id="designation"
                      maxLength={60}
                      minLength={3}
                      onInput={handleAphabetsChange}
                      className="form-control trio_mandatory"
                      placeholder="Enter Designation* "
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="inpContainer inpContainerSeo mt-2">
                    <label className="no_prsnl_id">No. of Positions </label>
                    <input
                      type="text"
                      name="no_of_position"
                      defaultValue={editStaffDataDetails.no_of_position || ""}
                      id="no_of_position"
                      maxLength={10}
                      minLength={1}
                      onInput={handleNumbersChange}
                      className="form-control trio_mandatory"
                      placeholder="Enter No. of Positions* "
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="inpContainer inpContainerSeo mt-2">
                    <label className="no_prsnl_id">Qualification </label>
                    <input
                      type="text"
                      name="qualification"
                      defaultValue={editStaffDataDetails.qualification || ""}
                      id="qualification"
                      maxLength={300}
                      minLength={1}
                      onInput={handleIaphabetnumberChange}
                      className="form-control trio_mandatory"
                      placeholder="Enter Qualification* "
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="inpContainer inpContainerSeo mt-2">
                    <label className="no_prsnl_id">Salary Details </label>
                    <input
                      type="text"
                      name="salary_details"
                      defaultValue={editStaffDataDetails.salary_details || ""}
                      id="salary_details"
                      maxLength={300}
                      minLength={1}
                      onInput={handleIaphabetnumberChange}
                      className="form-control trio_mandatory"
                      placeholder="Enter Salary Details*"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="inpContainer inpContainerSeo mt-2">
                    <label className="no_prsnl_id">Min Experience</label>
                    <input
                      type="text"
                      name="min_experience"
                      defaultValue={editStaffDataDetails.min_experience || ""}
                      id="min_experience"
                      maxLength={10}
                      minLength={1}
                      onInput={handleNumbersDecimalChange}
                      className="form-control trio_mandatory"
                      placeholder="Enter Min Experience* "
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="inpContainer inpContainerSeo mt-2">
                    <label className="no_prsnl_id">Max Experience</label>
                    <input
                      type="text"
                      name="max_experience"
                      defaultValue={editStaffDataDetails.max_experience || ""}
                      id="max_experience"
                      maxLength={10}
                      minLength={1}
                      onInput={handleNumbersDecimalChange}
                      className="form-control trio_mandatory"
                      placeholder="Enter Max Experience* "
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="inpContainer inpContainerSeo mt-2">
                    <label className="no_prsnl_id">Skills </label>
                    <textarea
                      type="text"
                      name="skills"
                      defaultValue={editStaffDataDetails.skills || ""}
                      id="skills"
                      maxLength={300}
                      minLength={1}
                      onInput={handleIaphabetnumberChange}
                      className="textareBlogs mt-0 form-control trio_mandatory"
                      placeholder="Enter Skills* "
                    ></textarea>
                    <span className="condition_error"></span>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="addBlogBtnDiv">
                    <button
                      type="button"
                      onClick={() =>
                        handleSaveChangesdynamic(
                          "addNewStaff",
                          save_update_Vacancy
                        )
                      }
                      className="btn btn-secondary mt-3 save-cap-btn"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      <Modal
        show={modalShow}
        className="confirmModal"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
        <Modal.Body className="confirmBody">
          <p className="modalText">Do You Want to Delete This Data?</p>
        </Modal.Body>
        <Modal.Footer className="confirmModalFooter">
          <Button
            className={`closeConfirmAeBtn closeConfirmBtn`}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            className={`confirmAeBtn Confirm_Deactive`}
            onClick={handleDeactivate}
          >
            <label
              style={{ cursor: "pointer" }}
              htmlFor={`dynamic_id${SelectedData.primary_id}`}
            >
              Yes
            </label>
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalShowAccept}
        className="confirmModal"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
        <Modal.Body className="confirmBody">
          <p className="modalText">Do You Want to Accept This Application?</p>
        </Modal.Body>
        <Modal.Footer className="confirmModalFooter">
          <Button
            className={`closeConfirmAeBtn closeConfirmBtn`}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            className={`confirmAeBtn Confirm_Deactive`}
            onClick={handleDeactivateAccept}
          >
            <label
              style={{ cursor: "pointer" }}
              htmlFor={`dynamic_id${SelectedData.primary_id}`}
            >
              Yes
            </label>
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalarrived}
        className="confirmModal"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
        <Modal.Body className="confirmBody">
          <p className="modalText">Are You Sure Arrived?</p>
        </Modal.Body>
        <Modal.Footer className="confirmModalFooter">
          <Button
            className={`closeConfirmAeBtn closeConfirmBtn`}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            className={`confirmAeBtn Confirm_Deactive`}
            onClick={handleArrivedAccept}
          >
            <label
              style={{ cursor: "pointer" }}
              htmlFor={`dynamic_id${SelectedData.primary_id}`}
            >
              Yes
            </label>
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalshudluelink}
        className="ReScheduleModal"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
        <Modal.Body>
          <div className="infoDetails">
            <p>Name: {SelectedData.user_name}</p>
            <p>Mobile No: {SelectedData.job_mobile_no}</p>
            <p>Email: {SelectedData.job_email}</p>
          </div>
          <form id="addNewStaff2222">
            <div className="row">
              <div className="col-md-6">
                <label className="no_prsnl_id">Date</label>
                <div className="calendar_month_icon image_icon_position1 curser_Pointer mt-2">
                  <input
                    type="date"
                    name="arrived_date"
                    id="arrived_date"
                    min={computeTodayDate}
                    placeholder="Enter Date"
                    className=" trio_mandatory form-control  input_field_custom1  "
                    onClick={() => openCustomDatePicker("arrived_date")}
                  />
                  <span className="condition_error"></span>
                </div>
              </div>

              <div className="col-md-6">
                <div className="inpContainer inpContainerSeo mt-2">
                  <label className="no_prsnl_id">Time</label>
                  <input
                    type="time"
                    name="arrived_time"
                    id="arrived_time"
                    className="textareBlogs mt-0 form-control trio_mandatory"
                    placeholder="Enter Time"
                  />
                  <span className="condition_error"></span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="inpContainer inpContainerSeo mt-2">
                  <label className="no_prsnl_id">
                    Online Meeting if Online Meet
                  </label>
                  <input
                    type="text"
                    name="link_interview"
                    id="link_interview"
                    onInput={handleURLChange}
                    className="textareBlogs mt-0 form-control"
                    placeholder="Enter Online Meeting"
                  />
                  <span className="condition_error"></span>
                </div>
              </div>
              <input
                type="hidden"
                name="flag_for"
                id="flag_for"
                className="form-control trio_mandatory"
                defaultValue="2"
                placeholder="Enter id"
              />
              <input
                type="hidden"
                name="id_for_update"
                id="id_for_update"
                className="form-control trio_mandatory"
                defaultValue={SelectedData.primary_id}
                placeholder="Enter id"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="reSchduleFOotr">
          <Button
            className={`closeConfirmAeBtn closeConfirmBtn`}
            onClick={handleClose}
          >
            <label style={{ cursor: "pointer" }}>No</label>
          </Button>
          <Button
            className={`confirmAeBtn Confirm_Deactive`}
            onClick={() =>
              handleSaveChangesdynamic_update(
                "addNewStaff2222",
                update_data_career
              )
            }
          >
            <label
              style={{ cursor: "pointer" }}
              htmlFor={`dynamic_id${SelectedData.primary_id}`}
            >
              Send
            </label>
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalreject}
        className="ReScheduleModal"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
        <Modal.Body>
          <div className="infoDetails">
            <p>Name: {SelectedData.user_name}</p>
            <p>Mobile No: {SelectedData.job_mobile_no}</p>
            <p>Email: {SelectedData.job_email}</p>
          </div>
          <form id="addNewStaff2222reject">
            <div className="col-md-12">
              <div className="inpContainer inpContainerSeo mt-2">
                <label className="no_prsnl_id">Reason </label>
                <textarea
                  type="text"
                  name="comment_reason"
                  id="comment_reason"
                  maxLength={300}
                  minLength={1}
                  onInput={handleIaphabetnumberChange}
                  className="textareBlogs mt-0 form-control trio_mandatory"
                  placeholder="Enter Reason* "
                ></textarea>
                <span className="condition_error"></span>
              </div>
            </div>
            <input
              type="hidden"
              name="flag_for"
              id="flag_for"
              className="form-control trio_mandatory"
              defaultValue="6"
              placeholder="Enter id "
            />
            <input
              type="hidden"
              name="id_for_update"
              id="id_for_update"
              className="form-control trio_mandatory"
              defaultValue={SelectedData.primary_id}
              placeholder="Enter id "
            />
          </form>
        </Modal.Body>
        <Modal.Footer className="confirmModalFooter">
          <Button
            className={`closeConfirmAeBtn closeConfirmBtn`}
            onClick={handleClose}
          >
            <label style={{ cursor: "pointer" }}>No</label>
          </Button>
          <Button
            className={`confirmAeBtn Confirm_Deactive`}
            onClick={() =>
              handleSaveChangesdynamic_update(
                "addNewStaff2222reject",
                update_data_career
              )
            }
          >
            <label
              style={{ cursor: "pointer" }}
              htmlFor={`dynamic_id${SelectedData.primary_id}`}
            >
              Send
            </label>
          </Button>
        </Modal.Footer>
      </Modal>
      {/*-----------Schdullng mdll */}
      <Modal
        show={modalselected}
        className="ReScheduleModal"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="infoDetails">
            <p className="modalText">Name: {SelectedData.user_name}</p>
            <p className="modalText">Mobile No: {SelectedData.job_mobile_no}</p>
            <p className="modalText">Email: {SelectedData.job_email}</p>
          </div>
          <form id="addNewStaff2222selected">
            <div className="row">
              <div className="col-md-6">
                <label className="no_prsnl_id">Date </label>
                <div className="calendar_month_icon image_icon_position1 curser_Pointer mt-2">
                  <input
                    type="date"
                    name="join_date"
                    id="join_date"
                    min={computeTodayDate}
                    placeholder="Enter Date"
                    className=" trio_mandatory form-control  input_field_custom1  "
                    onClick={() => openCustomDatePicker("join_date")}
                  />
                  <span className="condition_error"></span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="inpContainer inpContainerSeo mt-2">
                  <label className="no_prsnl_id">Time </label>
                  <input
                    type="time"
                    name="join_time"
                    id="join_time"
                    className="textareBlogs mt-0 form-control trio_mandatory"
                    placeholder="Enter Skills* "
                  />
                  <span className="condition_error"></span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="inpContainer inpContainerSeo mt-2">
                  <label className="no_prsnl_id">Report To </label>
                  <input
                    type="text"
                    maxLength={300}
                    minLength={1}
                    onInput={handleIaphabetnumberChange}
                    name="report_to"
                    id="report_to"
                    className="textareBlogs mt-0 form-control trio_mandatory"
                    placeholder="Enter Report To* "
                  />
                  <span className="condition_error"></span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="inpContainer inpContainerSeo mt-2">
                  <label className="no_prsnl_id">Salary(Monthly) </label>
                  <input
                    type="text"
                    maxLength={20}
                    minLength={1}
                    onInput={handleNumbersChange}
                    name="salary_amount"
                    id="salary_amount"
                    className="textareBlogs mt-0 form-control trio_mandatory"
                    placeholder="Enter Salary(Monthly)* "
                  />
                  <span className="condition_error"></span>
                </div>
              </div>
            </div>
            <input
              type="hidden"
              name="flag_for"
              id="flag_for"
              className="form-control trio_mandatory"
              defaultValue="5"
              placeholder="Enter id "
            />
            <input
              type="hidden"
              name="id_for_update"
              id="id_for_update"
              className="form-control trio_mandatory"
              defaultValue={SelectedData.primary_id}
              placeholder="Enter id "
            />
          </form>
        </Modal.Body>
        <Modal.Footer className="confirmModalFooter">
          <Button
            className={`closeConfirmAeBtn closeConfirmBtn`}
            onClick={handleClose}
          >
            <label style={{ cursor: "pointer" }}>No</label>
          </Button>
          <Button
            className={`confirmAeBtn Confirm_Deactive`}
            onClick={() =>
              handleSaveChangesdynamic_update(
                "addNewStaff2222selected",
                update_data_career
              )
            }
          >
            <label
              style={{ cursor: "pointer" }}
              htmlFor={`dynamic_id${SelectedData.primary_id}`}
            >
              Send
            </label>
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalShowDecline}
        className="confirmModal"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
        <Modal.Body className="confirmBody">
          <p className="modalText">Do You Want to Reject This Application?</p>
        </Modal.Body>
        <Modal.Footer className="confirmModalFooter">
          <Button
            className={`closeConfirmAeBtn closeConfirmBtn`}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            className={`confirmAeBtn Confirm_Deactive`}
            onClick={handleDeactivateDecline}
          >
            <label
              style={{ cursor: "pointer" }}
              htmlFor={`dynamic_id${SelectedData.primary_id}`}
            >
              Yes
            </label>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default VacancyWebsite;
